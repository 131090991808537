import * as React from "react"
import { FiChevronRight } from "react-icons/fi"
import { gql } from "@apollo/client"
import { Box, Center, Heading, Spinner, Stack, Text, useColorModeValue } from "@chakra-ui/react"

import type { EntityItemFragment } from "lib/graphql"
import { useGetUserEntitiesQuery } from "lib/graphql"
import { useBetterTranslation } from "lib/hooks/useTranslation"

import { LinkButton } from "./LinkButton"

const _ = gql`
  fragment EntityItem on Entity {
    id
    name
  }
  query GetUserEntities {
    me {
      id
      entities {
        ...EntityItem
      }
    }
  }
`

export function UserEntities() {
  const bt = useBetterTranslation()
  const { data, loading } = useGetUserEntitiesQuery()
  const entities = data?.me?.entities
  return (
    <Stack spacing={4}>
      <Heading as="h2" color="pink.500" size="md">
        {bt({ en: "What would you like to see?", nl: "Wat zou je willen zien?" })}
      </Heading>
      {loading ? (
        <Center w="100%" h="80px">
          <Spinner size="lg" color="pink.500" />
        </Center>
      ) : (
        <Stack spacing={2}>{entities?.map((entity) => <EntityItem entity={entity} key={entity.id} />)}</Stack>
      )}
    </Stack>
  )
}

interface Props {
  entity: EntityItemFragment
}

function EntityItem({ entity }: Props) {
  return (
    <LinkButton
      href={`/entities/${entity.id}`}
      backgroundColor={useColorModeValue("blackAlpha.50", "whiteAlpha.100")}
      px={4}
      py={6}
      justifyContent="space-between"
    >
      <Text fontSize="xl" fontWeight="bold">
        {entity.name}
      </Text>
      <Box as={FiChevronRight} />
    </LinkButton>
  )
}
