import * as React from "react"
import { gql } from "@apollo/client"
import { HStack, Text, useColorModeValue } from "@chakra-ui/react"

import type { LatestReportItemFragment } from "../lib/graphql"
import { REPORT_TYPES } from "../lib/static/reportType"
import { LinkButton } from "./LinkButton"
import { Status } from "./Status"

const _ = gql`
  fragment LatestReportItem on Report {
    id
    createdAt
    quarter
    year
    type
    status
    file
    entity {
      id
      name
    }
  }
`

interface Props {
  report: LatestReportItemFragment
}

export function LatestReportItem({ report }: Props) {
  const bg = useColorModeValue("blackAlpha.50", "whiteAlpha.100")
  return (
    <LinkButton
      href={`/entities/${report.entity?.id}/${report.year}/${
        report.quarter ? report.quarter + "/" : ""
      }report/${report.id}`}
      backgroundColor={bg}
      px={4}
      py={6}
      justifyContent="space-between"
    >
      <HStack alignItems="center" w="100%">
        <Text fontWeight="bold" marginRight="auto">
          {REPORT_TYPES[report.type]} · {report.entity.name} · {report.year}
          {report.quarter ? ` · ${report.quarter}` : ""}
        </Text>
        <Status fontWeight="bold" mr={4} py={1} px={2} status={report.status} />
      </HStack>
    </LinkButton>
  )
}
