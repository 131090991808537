import * as React from "react"
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Stack,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react"
import Head from "next/head"

import { IS_STAGING } from "lib/config"
import { useMe } from "lib/hooks/useMe"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import { HomeLayout } from "components/HomeLayout"
import { LatestReports } from "components/LatestReports"
import { UserEntities } from "components/UserEntities"
import { UserTasks } from "components/UserTasks"
import { DownloadApp } from "components/DownloadApp"

export default function Home() {
  const { me } = useMe()

  const appStoreSrc = useColorModeValue("/app-store-black.png", "/app-store-white.png")
  const playStoreSrc = useColorModeValue("/play-store-black.png", "/play-store-white.png")

  const bt = useBetterTranslation()
  const [isMobile] = useMediaQuery("(max-width: 500px)")

  if (!me) return null

  return (
    <>
      <Head>
        <title>Grey Men</title>
      </Head>
      {isMobile ? (
        <DownloadApp />
      ) : (
        <>
          <Box minH="calc(100vh - 65px)" pb={12}>
            <Heading as="h1" mb={8} pt={16}>
              {bt({ en: "Hey", nl: "Hey" })} {me.firstName}
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={12} w="100%">
              <Stack w="100%" spacing={12}>
                <UserEntities />
                <LatestReports />
              </Stack>
              {me.id && <UserTasks userId={me.id} />}
            </SimpleGrid>
          </Box>
          <Flex
            h={{ base: "170px", sm: "80px" }}
            justifyContent={{ base: "center", sm: "flex-end" }}
            pos="absolute"
            bottom={0}
            left={0}
            right={0}
            flexDir={{ base: "column", sm: "row" }}
            px={{
              base: 4,
              md: 10,
              lg: 24,
              xl: 32,
            }}
          >
            <Link
              isExternal
              href={
                IS_STAGING
                  ? "https://beta.itunes.apple.com/v1/app/1645518189"
                  : "https://apps.apple.com/app/grey-men/id1665128755"
              }
              mr={{ base: 0, sm: 2 }}
              mb={{ base: 2, sm: 0 }}
            >
              <Image alt="app store logo" src={appStoreSrc} w="150px" margin="0 auto" />
            </Link>
            <Link
              isExternal
              href={
                IS_STAGING
                  ? "https://play.google.com/apps/test/co.noquarter.greymenapp/6"
                  : "https://play.google.com/store/apps/details?id=co.noquarter.greymenapp"
              }
            >
              <Image alt="play logo" src={playStoreSrc} w="150px" margin="0 auto" />
            </Link>
          </Flex>
        </>
      )}
    </>
  )
}

Home.getLayout = (page: React.ReactNode) => <HomeLayout>{page}</HomeLayout>
