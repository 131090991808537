import * as React from "react"
import * as c from "@chakra-ui/react"
import { IS_STAGING } from "lib/config"
import { useBetterTranslation } from "lib/hooks/useTranslation"

export function DownloadApp() {
  const bt = useBetterTranslation()
  return (
    <c.Flex flexDir="column" h="calc(100vh - 160px)" justifyContent="space-between" pt={10} pb={16}>
      <c.Stack spacing={6} justify="center" textAlign="center">
        <c.Image
          alt="greymen logo"
          src={c.useColorModeValue("/logo.png", "/logo-white.png")}
          w="60px"
          alignSelf="center"
        />
        <c.Heading as="h1" fontSize="4xl">
          {bt({ en: "Get the App", nl: "Download de app" })}
        </c.Heading>
        <c.Text fontSize="xl">
          {bt({
            en: "All of your important admininstration in a single place. Simple to use.",
            nl: "Al je belangrijke administratie op één plek. Eenvoudig in gebruik.",
          })}
        </c.Text>
      </c.Stack>
      <c.Center>
        <c.Stack spacing={4}>
          <c.Link
            isExternal
            href={
              IS_STAGING
                ? "https://beta.itunes.apple.com/v1/app/1645518189"
                : "https://apps.apple.com/app/grey-men/id1665128755"
            }
          >
            <c.Image
              alt="app store logo"
              src={c.useColorModeValue("/app-store-black.png", "/app-store-white.png")}
              w="190px"
            />
          </c.Link>
          <c.Link
            isExternal
            href={
              IS_STAGING
                ? "https://play.google.com/apps/test/co.noquarter.greymenapp/6"
                : "https://play.google.com/store/apps/details?id=co.noquarter.greymenapp"
            }
          >
            <c.Image
              alt="play logo"
              src={c.useColorModeValue("/play-store-black.png", "/play-store-white.png")}
              w="190px"
            />
          </c.Link>
        </c.Stack>
      </c.Center>
    </c.Flex>
  )
}
