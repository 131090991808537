import * as React from "react"
import { gql } from "@apollo/client"
import { Heading, Spinner, Stack } from "@chakra-ui/react"

import { useGetLatestReportsQuery } from "lib/graphql"
import { useBetterTranslation } from "lib/hooks/useTranslation"

import { LatestReportItem } from "./LatestReportItem"

const _ = gql`
  query GetLatestReports {
    me {
      id
      latestReports {
        ...LatestReportItem
      }
    }
  }
`

export function LatestReports() {
  const bt = useBetterTranslation()
  const { data, loading } = useGetLatestReportsQuery()

  const reports = data?.me?.latestReports || []

  if (reports.length === 0) return null
  return (
    <Stack spacing={4}>
      <Heading as="h2" color="pink.500" size="md">
        {bt({ en: "Your latest reports", nl: "Jouw laatste reports" })}
      </Heading>

      {loading ? (
        <div className="flex h-20 items-end justify-center">
          <Spinner />
        </div>
      ) : (
        reports?.map((report) => <LatestReportItem key={report.id} report={report} />)
      )}
    </Stack>
  )
}
